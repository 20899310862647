<template>
  <table class="analytics-table">
    <thead>
    <tr>
      <th v-if="show_title"></th>
      <th v-if="group_id">Datum</th>
      <th v-if="secondary_group_id">{{ group_headline(secondary_group_id) }}</th>
      <th v-if="tertiary_group_id">{{ group_headline(tertiary_group_id) }}</th>
      <th v-if="channel_id && secondary_group_id !== 'channel_id'">Channel</th>
      <th v-if="platform_id && secondary_group_id !== 'platform_id'">Platform</th>
      <th v-if="manager_id && secondary_group_id !== 'manager_id'">Manager</th>
      <th v-if="team_id && secondary_group_id !== 'team_id'">Team</th>
      <th v-if="customer_id && secondary_group_id !== 'customer_id'">Kunde</th>
      <th v-if="customer_type_id && secondary_group_id !== 'customer_type_id'">Kunden-Typ</th>
      <th v-if="advertiser_id && secondary_group_id !== 'advertiser_id'">Advertiser</th>
      <th v-if="turnover_group_id && secondary_group_id !== 'turnover_group_id'">Umsatzgruppe</th>
      <th v-if="media_planning_id && secondary_group_id !== 'media_planning_id'">Mediaplan</th>
      <th v-if="channel_id && tertiary_group_id !== 'channel_id'">Channel</th>
      <th v-if="platform_id && tertiary_group_id !== 'platform_id'">Platform</th>
      <th v-if="manager_id && tertiary_group_id !== 'manager_id'">Manager</th>
      <th v-if="team_id && tertiary_group_id !== 'team_id'">Team</th>
      <th v-if="customer_id && tertiary_group_id !== 'customer_id'">Kunde</th>
      <th v-if="customer_type_id && tertiary_group_id !== 'customer_type_id'">Kunden-Typ</th>
      <th v-if="advertiser_id && tertiary_group_id !== 'advertiser_id'">Advertiser</th>
      <th v-if="turnover_group_id && tertiary_group_id !== 'turnover_group_id'">Umsatzgruppe</th>
      <th v-if="media_planning_id && tertiary_group_id !== 'media_planning_id'">Mediaplan</th>
      <th v-if="count_media_plannings">Mediapläne</th>
      <th v-if="count_campaigns">Kampagnen</th>
      <th v-if="show_impressions">Impressions</th>
      <th v-if="show_clicks">Clicks</th>
      <th v-if="show_click_rate">CTR</th>
      <th v-if="show_complete">Complete</th>
      <th v-if="show_complete_rate">{{ complete_rate_title }}</th>
      <th v-if="show_video_views">Video views</th>
      <th v-if="show_video_view_rate">{{ video_view_rate_title }}</th>
      <th v-if="show_conversions">{{ conversions_title }}</th>
      <th v-if="show_conversion_rate">{{ conversion_rate_title }}</th>
      <th v-if="show_conversions_value">{{ conversions_value_title }}</th>
      <th v-if="show_turnover">Umsatz</th>
      <th v-if="show_cost">Kosten</th>
      <th v-if="show_margin">Erlös</th>
      <th v-if="show_margin_percent">Erlös %</th>
      <th v-if="show_cost_metrics"><abbr title="Cost per mille">CPM</abbr></th>
      <th v-if="show_cost_metrics"><abbr title="Cost per click">CPC</abbr></th>
      <th v-if="show_cost_metrics"><abbr title="Cost per conversion">CPCONV</abbr></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="point in analytics">
      <td v-if="show_title">{{ point.title }}</td>
      <td v-if="group_id">
        {{ format_date_time(point._id) }}
      </td>
      <td v-if="secondary_group_id">
        <template v-if="secondary_group_id === 'turnover_group_id'">
          <turnover-group-info :turnover_groups="turnover_groups"
                               :turnover_group_id="point._id['turnover_group_id']"></turnover-group-info>
        </template>
        <template v-else>
          {{ group_title(secondary_group_id, point._id) }}
        </template>
      </td>
      <td v-if="channel_id && secondary_group_id !== 'channel_id'">{{ channel_name(channel_id) }}</td>
      <td v-if="platform_id && secondary_group_id !== 'platform_id'">{{ platform_name(platform_id) }}</td>
      <td v-if="manager_id && secondary_group_id !== 'manager_id'">{{ manager_name(manager_id) }}</td>
      <td v-if="team_id && secondary_group_id !== 'team_id'">{{ team_name(team_id) }}</td>
      <td v-if="customer_id && secondary_group_id !== 'customer_id'">{{ customer_title(customer_id) }}</td>
      <td v-if="customer_type_id && secondary_group_id !== 'customer_type_id'">
        {{ customer_type_title(customer_type_id) }}
      </td>
      <td v-if="advertiser_id && secondary_group_id !== 'advertiser_id'">{{ advertiser_title(advertiser_id) }}</td>
      <td v-if="turnover_group_id && secondary_group_id !== 'turnover_group_id'">
        <turnover-group-info turnover_groups="turnover_groups"
                             turnover_group_id="turnover_group_id"></turnover-group-info>
      </td>
      <td v-if="media_planning_id && secondary_group_id !== 'media_planning_id'">
        {{ media_planning_title(media_planning_id) }}
      </td>
      <td v-if="tertiary_group_id">
        <template v-if="tertiary_group_id === 'turnover_group_id'">
          <turnover-group-info :turnover_groups="turnover_groups"
                               :turnover_group_id="point._id['turnover_group_id']"></turnover-group-info>
        </template>
        <template v-else>
          {{ group_title(tertiary_group_id, point._id) }}
        </template>
      </td>
      <td v-if="channel_id && tertiary_group_id !== 'channel_id'">{{ channel_name(channel_id) }}</td>
      <td v-if="platform_id && tertiary_group_id !== 'platform_id'">{{ platform_name(platform_id) }}</td>
      <td v-if="manager_id && tertiary_group_id !== 'manager_id'">{{ manager_name(manager_id) }}</td>
      <td v-if="team_id && tertiary_group_id !== 'team_id'">{{ team_name(team_id) }}</td>
      <td v-if="customer_id && tertiary_group_id !== 'customer_id'">{{ customer_title(customer_id) }}</td>
      <td v-if="customer_type_id && tertiary_group_id !== 'customer_type_id'">
        {{ customer_type_title(customer_type_id) }}
      </td>
      <td v-if="advertiser_id && tertiary_group_id !== 'advertiser_id'">{{ advertiser_title(advertiser_id) }}</td>
      <td v-if="turnover_group_id && tertiary_group_id !== 'turnover_group_id'">
        <turnover-group-info turnover_groups="turnover_groups"
                             turnover_group_id="turnover_group_id"></turnover-group-info>
      </td>
      <td v-if="media_planning_id && tertiary_group_id !== 'media_planning_id'">
        {{ media_planning_title(media_planning_id) }}
      </td>
      <td v-if="count_media_plannings">{{ point.media_planning_count }}</td>
      <td v-if="count_campaigns">{{ point.campaign_count }}</td>
      <td v-if="show_impressions">{{ point.impressions }}</td>
      <td v-if="show_clicks">{{ point.clicks }}</td>
      <td v-if="show_click_rate">
        <percent-rate :rate="point.click_rate"></percent-rate>
      </td>
      <td v-if="show_complete">{{ point.complete }}</td>
      <td v-if="show_complete_rate">
        <percent-rate :rate="point.complete_rate"></percent-rate>
      </td>

      <td v-if="show_video_views">{{ point.video_views }}</td>
      <td v-if="show_video_view_rate">
        <percent-rate :rate="point.video_view_rate"></percent-rate>
      </td>


      <td v-if="show_conversions">
        <vue-numeric v-model="point.conversions" separator="." v-bind:precision=2 v-bind:read-only=true></vue-numeric>
      </td>
      <td v-if="show_conversion_rate">
        <percent-rate :rate="point.conversion_rate"></percent-rate>
      </td>
      <td v-if="show_conversions_value">
        <vue-numeric v-model="point.conversions_value"
                     separator="."
                     v-bind:precision=2
                     v-bind:read-only=true
                     currency="€"
                     currency-symbol-position="suffix">
        </vue-numeric>
      </td>

      <td v-if="show_turnover">
        <vue-numeric v-model="point.turnover"
                     separator="."
                     v-bind:precision=2
                     v-bind:read-only=true
                     currency="€"
                     currency-symbol-position="suffix">
        </vue-numeric>
      </td>

      <td v-if="show_cost">
        <vue-numeric v-model="point.cost"
                     separator="."
                     v-bind:precision=2
                     v-bind:read-only=true
                     currency="€"
                     currency-symbol-position="suffix">
        </vue-numeric>
      </td>

      <td v-if="show_margin">
        <vue-numeric v-model="point.margin"
                     separator="."
                     v-bind:precision=2
                     v-bind:read-only=true
                     v-bind:minus="true"
                     currency="€"
                     currency-symbol-position="suffix">
        </vue-numeric>
      </td>

      <td v-if="show_margin_percent">
        <small v-if="point.margin_percent || point.margin_percent === 0.0" class="label secondary">
          <percent-rate :rate="point.margin_percent" :min_rate="0.3"></percent-rate>
        </small>
      </td>

      <td v-if="show_cost_metrics">
        <vue-numeric v-if="typeof point.cpm === 'number'"
                     v-model="point.cpm"
                     separator="."
                     v-bind:precision=2
                     v-bind:read-only=true
                     currency="€"
                     currency-symbol-position="suffix">
        </vue-numeric>
      </td>

      <td v-if="show_cost_metrics">
        <vue-numeric v-if="typeof point.cpc === 'number'"
                     v-model="point.cpc"
                     separator="."
                     v-bind:precision=2
                     v-bind:read-only=true
                     currency="€"
                     currency-symbol-position="suffix">
        </vue-numeric>
      </td>

      <td v-if="show_cost_metrics">
        <vue-numeric v-if="typeof point.cost_per_conversion === 'number'"
                     v-model="point.cost_per_conversion"
                     separator="."
                     v-bind:precision=2
                     v-bind:read-only=true
                     currency="€"
                     currency-symbol-position="suffix">
        </vue-numeric>
      </td>
    </tr>
    </tbody>
    <tfoot v-if="summary.length">
    <tr v-for="sum in summary">
      <td>Summe:</td>
      <td v-if="secondary_group_id"></td>
      <td v-if="channel_id && secondary_group_id !== 'channel_id'">{{ channel_name(channel_id) }}</td>
      <td v-if="platform_id && secondary_group_id !== 'platform_id'">{{ platform_name(platform_id) }}</td>
      <td v-if="manager_id && secondary_group_id !== 'manager_id'">{{ manager_name(manager_id) }}</td>
      <td v-if="team_id && secondary_group_id !== 'team_id'">{{ team_name(team_id) }}</td>
      <td v-if="customer_id && secondary_group_id !== 'customer_id'">{{ customer_title(customer_id) }}</td>
      <td v-if="customer_type_id && secondary_group_id !== 'customer_type_id'">
        {{ customer_type_title(customer_type_id) }}
      </td>
      <td v-if="advertiser_id && secondary_group_id !== 'advertiser_id'">{{ advertiser_title(advertiser_id) }}</td>
      <td v-if="turnover_group_id && secondary_group_id !== 'turnover_group_id'">
        <turnover-group-info turnover_groups="turnover_groups"
                             turnover_group_id="turnover_group_id"></turnover-group-info>
      </td>
      <td v-if="media_planning_id && secondary_group_id !== 'media_planning_id'">
        {{ media_planning_title(media_planning_id) }}
      </td>
      <td v-if="tertiary_group_id"></td>
      <td v-if="channel_id && tertiary_group_id !== 'channel_id'">{{ channel_name(channel_id) }}</td>
      <td v-if="platform_id && tertiary_group_id !== 'platform_id'">{{ platform_name(platform_id) }}</td>
      <td v-if="manager_id && tertiary_group_id !== 'manager_id'">{{ manager_name(manager_id) }}</td>
      <td v-if="team_id && tertiary_group_id !== 'team_id'">{{ team_name(team_id) }}</td>
      <td v-if="customer_id && tertiary_group_id !== 'customer_id'">{{ customer_title(customer_id) }}</td>
      <td v-if="customer_type_id && tertiary_group_id !== 'customer_type_id'">
        {{ customer_type_title(customer_type_id) }}
      </td>
      <td v-if="advertiser_id && tertiary_group_id !== 'advertiser_id'">{{ advertiser_title(advertiser_id) }}</td>
      <td v-if="turnover_group_id && tertiary_group_id !== 'turnover_group_id'">
        <turnover-group-info turnover_groups="turnover_groups"
                             turnover_group_id="turnover_group_id"></turnover-group-info>
      </td>
      <td v-if="media_planning_id && tertiary_group_id !== 'media_planning_id'">
        {{ media_planning_title(media_planning_id) }}
      </td>
      <td v-if="count_media_plannings">{{ sum.media_planning_count }}</td>
      <td v-if="count_campaigns">{{ sum.campaign_count }}</td>
      <td v-if="show_impressions">{{ sum.impressions }}</td>

      <td v-if="show_clicks">{{ sum.clicks }}</td>
      <td v-if="show_click_rate">
        <percent-rate :rate="sum.click_rate"></percent-rate>
      </td>

      <td v-if="show_complete">{{ sum.complete }}</td>
      <td v-if="show_complete_rate">
        <percent-rate :rate="sum.complete_rate"></percent-rate>
      </td>

      <td v-if="show_video_views">{{ sum.video_views }}</td>
      <td v-if="show_video_view_rate">
        <percent-rate :rate="sum.video_view_rate"></percent-rate>
      </td>

      <td v-if="show_conversions">{{ sum.conversions }}</td>
      <td v-if="show_conversion_rate">
        <percent-rate :rate="sum.conversion_rate"></percent-rate>
      </td>
      <td v-if="show_conversions_value">
        <vue-numeric v-model="sum.conversions_value"
                     separator="."
                     v-bind:precision=2
                     v-bind:read-only=true
                     currency="€"
                     currency-symbol-position="suffix">
        </vue-numeric>
      </td>

      <td v-if="show_turnover">
        <vue-numeric v-model="sum.turnover"
                     separator="."
                     v-bind:precision=2
                     v-bind:read-only=true
                     currency="€"
                     currency-symbol-position="suffix">
        </vue-numeric>
      </td>
      <td v-if="show_cost">
        <vue-numeric v-model="sum.cost"
                     separator="."
                     v-bind:precision=2
                     v-bind:read-only=true
                     currency="€"
                     currency-symbol-position="suffix">
        </vue-numeric>
      </td>
      <td v-if="show_margin">
        <vue-numeric v-model="sum.margin"
                     separator="."
                     v-bind:precision=2
                     v-bind:read-only=true
                     v-bind:minus="true"
                     currency="€"
                     currency-symbol-position="suffix">
        </vue-numeric>
      </td>
      <td v-if="show_margin_percent">
        <small v-if="typeof sum.margin_percent === 'number'" class="label secondary">
          <percent-rate :rate="sum.margin_percent" :min_rate="0.3"></percent-rate>
        </small>
      </td>
      <td v-if="show_cost_metrics">
        <vue-numeric v-if="typeof sum.cpm === 'number'"
                     v-model="sum.cpm"
                     separator="."
                     v-bind:precision=2
                     v-bind:read-only=true
                     currency="€"
                     currency-symbol-position="suffix">
        </vue-numeric>
      </td>
      <td v-if="show_cost_metrics">
        <vue-numeric v-if="typeof sum.cpc === 'number'"
                     v-model="sum.cpc"
                     separator="."
                     v-bind:precision=2
                     v-bind:read-only=true
                     currency="€"
                     currency-symbol-position="suffix">
        </vue-numeric>
      </td>
      <td v-if="show_cost_metrics">
        <vue-numeric v-if="typeof sum.cost_per_conversion === 'number'"
                     v-model="sum.cost_per_conversion"
                     separator="."
                     v-bind:precision=2
                     v-bind:read-only=true
                     currency="€"
                     currency-symbol-position="suffix">
        </vue-numeric>
      </td>
    </tr>
    </tfoot>
  </table>
</template>

<script>
import "./analytics-table.scss"
import TurnoverGroupInfo from "../turnover-group-info/turnover-group-info-app"
import PercentRate from "../percent-rate/percent-rate.vue"
import VueNumeric from 'vue-numeric'
import moment from "moment"

export default {
  name:       "analytics-table",
  props:      {
    group_id:               {
      required: false,
      default:  null,
    },
    secondary_group_id:     {
      required: false,
      default:  null,
    },
    tertiary_group_id:      {
      required: false,
      default:  null,
    },
    channel_id:             {
      required: false,
      default:  null,
    },
    platform_id:            {
      required: false,
      default:  null,
    },
    manager_id:             {
      required: false,
      default:  null,
    },
    team_id:                {
      required: false,
      default:  null,
    },
    customer_id:            {
      required: false,
      default:  null,
    },
    customer_type_id:       {
      required: false,
      default:  null,
    },
    advertiser_id:          {
      required: false,
      default:  null,
    },
    turnover_group_id:      {
      required: false,
      default:  null,
    },
    media_planning_id:      {
      required: false,
      default:  null,
    },
    channels:               {
      required: false,
      default:  function () {return []},
    },
    platforms:              {
      required: false,
      default:  function () {return []},
    },
    users:                  {
      required: false,
      default:  function () {return []},
    },
    teams:                  {
      required: false,
      default:  function () {return []},
    },
    customers:              {
      required: false,
      default:  function () {return []},
    },
    customer_types:         {
      required: false,
      default:  function () {return []},
    },
    advertisers:            {
      required: false,
      default:  function () {return []},
    },
    turnover_groups:        {
      required: false,
      default:  function () {return []},
    },
    media_plannings:        {
      required: false,
      default:  function () {return []},
    },
    count_media_plannings:  {
      required: false,
      default:  false,
    },
    count_campaigns:        {
      required: false,
      default:  false,
    },
    show_turnover:          {
      required: false,
      default:  false,
    },
    show_title:             {
      required: false,
      default:  false,
    },
    show_impressions:       {
      required: false,
      default:  false,
    },
    show_clicks:            {
      required: false,
      default:  false,
    },
    show_click_rate:        {
      required: false,
      default:  false,
    },
    show_complete:          {
      required: false,
      default:  false,
    },
    show_complete_rate:     {
      required: false,
      default:  false,
    },
    show_video_views:       {
      required: false,
      default:  false,
    },
    show_video_view_rate:   {
      required: false,
      default:  false,
    },
    show_conversions:       {
      required: false,
      default:  false,
    },
    show_conversion_rate:   {
      required: false,
      default:  false,
    },
    show_conversions_value: {
      required: false,
      default:  false,
    },
    show_cost:              {
      required: false,
      default:  false,
    },
    show_margin:            {
      required: false,
      default:  false,
    },
    show_margin_percent:    {
      required: false,
      default:  false,
    },
    show_cost_metrics:      {
      required: false,
      default:  false,
    },
    analytics:              {
      required: true,
    },
    summary:                {
      required: false,
      default:  function () {return []},
    },
  },
  components: {
    PercentRate,
    TurnoverGroupInfo,
    VueNumeric,
  },
  data() {
    return {}
  },
  methods:  {
    format_date_time(time) {
      if (this.is_loading) return
      if (!time) return
      if (this.group_id === "date") {
        return moment(time[this.group_id]).format("L")
      }
      if (this.group_id === "week") {
        const week_string = `${time["year"]}W${time["week"].toString().padStart(2, "0")}`
        return moment(week_string).format("wo [KW] YYYY")
      }
      if (this.group_id === "month") {
        const month_string = `${time["year"]}-${time["month"]}`
        return moment(month_string).format("MMMM YYYY")
      }
      if (this.group_id === "year") {
        return time[this.group_id]
      }
    },
    group_title(group_id, point_id) {
      if (group_id === "channel_id") return this.channel_name(point_id[group_id])
      if (group_id === "platform_id") return this.platform_name(point_id[group_id])
      if (group_id === "manager_id") return this.manager_name(point_id[group_id])
      if (group_id === "team_id") return this.team_name(point_id[group_id])
      if (group_id === "customer_id") return this.customer_title(point_id[group_id])
      if (group_id === "customer_type_id") return this.customer_type_title(point_id[group_id])
      if (group_id === "advertiser_id") return this.advertiser_title(point_id[group_id])
      if (group_id === "media_planning_id") return this.media_planning_title(point_id[group_id])
    },
    channel_name(channel_id) {
      const c = this.channel(channel_id);
      if (c) return c.title
    },
    channel(channel_id) {
      return this.channels.find(channel => channel.id === channel_id)
    },
    platform_name(platform_id) {
      const platform = this.platforms.find(platform => platform.id === platform_id)
      if (platform) return platform.title
    },
    manager_name(manager_id) {
      const manager = this.users.find(manager => manager.id === manager_id)
      if (manager) return manager.email
    },
    team_name(team_id) {
      const team = this.teams.find(team => team.id === team_id)
      if (team) return team.title
    },
    customer_title(customer_id) {
      const customer = this.customers.find(customer => customer.id === customer_id)
      if (customer) return customer.title
    },
    customer_type_title(customer_type_id) {
      const customer_type = this.customer_types.find(customer_type => customer_type.id === customer_type_id)
      if (customer_type) return customer_type.title
    },
    advertiser_title(advertiser_id) {
      const advertiser = this.advertisers.find(advertiser => advertiser.id === advertiser_id)
      if (advertiser) return advertiser.title
    },
    media_planning_title(id) {
      const media_planning = this.media_plannings.find(media_planning => media_planning.id === id)
      if (media_planning) return media_planning.title
    },
    group_headline(group_id) {
      return {
        channel_id:        "Channel",
        platform_id:       "Platform",
        manager_id:        "Manager",
        team_id:           "Team",
        customer_id:       "Kunde",
        customer_type_id:  "Kunden-Typ",
        advertiser_id:     "Advertiser",
        turnover_group_id: "Umsatzgruppe",
        media_planning_id: "Mediaplan",
      }[group_id]
    },
  },
  computed: {
    complete_rate_title() {
      return "VTR"
    },
    video_view_rate_title() {
      return "VVR"
    },
    conversions_title() {
      return "CONVS"
    },
    conversion_rate_title() {
      return "CVR"
    },
    conversions_value_title() {
      return "Conv value"
    },
  },
}
</script>
