<template>
  <form @submit.prevent="register_final_report" ref="form" class="register-final-report">
    <h1>Neuer Endreport</h1>

    <label>Advertiser auswählen</label>

    <multiselect v-model="final_report.advertiser"
                 :options="advertisers"
                 :close-on-select="true"
                 :clear-on-select="false"
                 :preserveSearch="true"
                 placeholder="Advertiser auswählen"
                 label="title"
                 track-by="id"
                 :allow-empty="!final_report.advertiser"
                 :multiple="false"
                 :disabled="loading"
                 :show-labels="false">
    </multiselect>

    <div class="register-final-report--channels">
      <template v-for="integration in integrations">
        <input type="radio"
               name="campaign_channel"
               :value="integration.channel_id"
               :id="`campaign_${integration.channel_id}`"
               v-model="final_report.channel_id"
               :disabled="final_report.id">
        <label :for="`campaign_${integration.channel_id}`">
          <channel-icon :channel_title="integration.channel_title"></channel-icon>
          {{ integration.channel_title }}
        </label>
      </template>
    </div>

    <template v-if="final_report.channel_id">
      <label>Kampagne auswählen</label>
      <multiselect v-model="final_report.campaigns"
                   :options="matching_campaigns"
                   :close-on-select="true"
                   :clear-on-select="false"
                   :preserveSearch="true"
                   placeholder="Kampagne auswählen"
                   :custom-label="campaign_title"
                   label="title"
                   track-by="id"
                   :allow-empty="true"
                   :multiple="true"
                   :taggable="false"
                   :disabled="loading">
      </multiselect>
    </template>

    <button v-if="final_report_configured"
            :disabled="loading"
            class="register-final-report--button"
            :class="disabled_button_class">
      <i class="fi-save"></i>
      Endreport registrieren
    </button>
  </form>
</template>

<script>
import "./register-final-report.scss"
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css"
import axios from 'axios'
import moment from 'moment'
import ChannelIcon from "../channel-icon/channel-icon-app"

export default {
  name: "register-final-report",
  components: {
    Multiselect,
    ChannelIcon,
  },
  props: {
    integrations: {
      required: true,
    },
  },
  data() {
    return {
      final_report: {
        advertiser: null,
        channel_id: null,
        campaigns: [],
      },
      campaigns: [],
      advertisers: [],
      loading: false,
    }
  },
  methods: {
    campaign_title({title, start_date, end_date, integrations}) {
      let formatted_title = title
      if (start_date && end_date) {
        formatted_title = `${formatted_title} (${this.format_date_time(start_date)} - ${this.format_date_time(end_date)})`
      }
      formatted_title = `${formatted_title} ${integrations.map(integration => integration.platform_title).join(", ")}`
      return formatted_title
    },
    format_date_time(date_time, format = "L") {
      return moment(date_time).format(format)
    },
    register_final_report() {
      if (!this.final_report.advertiser) return
      if (!this.final_report.campaigns.length) return

      axios({
              method: 'post',
              url: `/final_reports/register`,
              headers: {'X-Requested-With': 'XMLHttpRequest'},
              data: {
                advertiser_id: this.final_report.advertiser.id,
                channel_id: this.final_report.channel_id,
                campaign_ids: this.final_report.campaigns.map(campaign => campaign.id),
              },
            })
          .then((response) => {
            window.location.href = response.headers.location
          })
          .catch((error) => {
            console.log("ERROR", error.response.data);
          });
    }
  },
  computed: {
    matching_campaigns() {
      return this.campaigns.filter(campaign => {
        if (campaign.advertiser_id !== this.final_report.advertiser.id) return
        if (campaign.channel_id !== this.final_report.channel_id) return
        return true
      })
    },
    final_report_configured() {
      if (!this.final_report.channel_id) return
      if (!this.final_report.campaigns.length) return
      return true
    },
    disabled_button_class() {
      if (this.loading) {
        return "register-final-report--button--disabled"
      }
    },
  },
  watch: {
    "final_report.channel_id"() {
      this.final_report.campaigns = []
    },
  },
  mounted() {
    axios({
            method: 'get',
            url: `/campaigns.json`,
            headers: {'X-Requested-With': 'XMLHttpRequest'},
          })
        .then(response => {
          this.campaigns = response.data
        })
        .catch(error => {
          console.log("ERROR", error.response.data)
        })
    axios({
            method: 'get',
            url: `/advertisers.json`,
            headers: {'X-Requested-With': 'XMLHttpRequest'},
          })
        .then(response => {
          this.advertisers = response.data
        })
        .catch(error => {
          console.log("ERROR", error.response.data)
        })
  },
}
</script>
