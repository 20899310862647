<template>
  <div class="media-planning-campaign-reports">
    <div v-if="media_planning.id" class="media-planning-campaign-reports--actions">
      <a v-if="campaign.reports.length" @click="recent = !recent"
         class="media-planning-campaign-reports--button"
         title="Aktuelle Reports">
        <boolean-icon :bool="recent" :color="false"></boolean-icon>
        Aktuelle Reports
      </a>

      <a v-if="campaign.reports.length && !campaign.dummy" @click="total = !total"
         class="media-planning-campaign-reports--button"
         title="Nach Tag aufaddieren">
        <boolean-icon :bool="total" :color="false"></boolean-icon>
        Total
      </a>

      <a v-if="!total && campaign.reports.length && !campaign.dummy && available_integrations.includes('ActiveAgent')"
         @click="active_agent_platform = !active_agent_platform"
         class="media-planning-campaign-reports--button"
         title="ActiveAgent">
        <boolean-icon :bool="active_agent_platform" :color="false"></boolean-icon>
        ActiveAgent
      </a>

      <a v-if="!total && campaign.reports.length && !campaign.dummy && available_integrations.includes('GoogleAds')"
         @click="google_ads_platform = !google_ads_platform"
         class="media-planning-campaign-reports--button"
         title="GoogleAds">
        <boolean-icon :bool="google_ads_platform" :color="false"></boolean-icon>
        GoogleAds
      </a>

      <a v-if="!total && campaign.reports.length && !campaign.dummy && available_integrations.includes('Invidi')"
         @click="invidi_platform = !invidi_platform"
         class="media-planning-campaign-reports--button"
         title="Invidi">
        <boolean-icon :bool="invidi_platform" :color="false"></boolean-icon>
        Invidi
      </a>

      <a v-if="!total && campaign.reports.length && !campaign.dummy && available_integrations.includes('Outbrain')"
         @click="outbrain_platform = !outbrain_platform"
         class="media-planning-campaign-reports--button"
         title="Outbrain">
        <boolean-icon :bool="outbrain_platform" :color="false"></boolean-icon>
        Outbrain
      </a>

      <a v-if="!total && campaign.reports.length && !campaign.dummy && available_integrations.includes('Hawk')"
         @click="hawk_platform = !hawk_platform"
         class="media-planning-campaign-reports--button"
         title="Hawk">
        <boolean-icon :bool="hawk_platform" :color="false"></boolean-icon>
        Hawk
      </a>

      <a v-if="!total && campaign.reports.length && !campaign.dummy && available_integrations.includes('SpotifyAds')"
         @click="spotify_ads_platform = !spotify_ads_platform"
         class="media-planning-campaign-reports--button"
         title="SpotifyAds">
        <boolean-icon :bool="spotify_ads_platform" :color="false"></boolean-icon>
        SpotifyAds
      </a>

      <a v-if="!total && campaign.reports.length && !campaign.dummy && available_integrations.includes('Taboola')"
         @click="taboola_platform = !taboola_platform"
         class="media-planning-campaign-reports--button"
         title="Taboola">
        <boolean-icon :bool="taboola_platform" :color="false"></boolean-icon>
        Taboola
      </a>

      <a v-if="!total && campaign.reports.length && !campaign.dummy && available_integrations.includes('Teads')"
         @click="teads_platform = !teads_platform"
         class="media-planning-campaign-reports--button"
         title="Teads">
        <boolean-icon :bool="teads_platform" :color="false"></boolean-icon>
        Teads
      </a>

      <a v-if="!total && campaign.reports.length && !campaign.dummy && available_integrations.includes('Verizon')"
         @click="verizon_platform = !verizon_platform"
         class="media-planning-campaign-reports--button"
         title="Verizon">
        <boolean-icon :bool="verizon_platform" :color="false"></boolean-icon>
        Verizon
      </a>

      <a v-if="!total && campaign.reports.length && !campaign.dummy && available_integrations.includes('Xandr')"
         @click="xandr_platform = !xandr_platform"
         class="media-planning-campaign-reports--button"
         title="Xandr">
        <boolean-icon :bool="xandr_platform" :color="false"></boolean-icon>
        Xandr
      </a>

      <a v-if="campaign.reports.length"
         @click="show_commercial_insights = !show_commercial_insights"
         class="media-planning-campaign-reports--button"
         title="Umsatz Insights">
        <boolean-icon :bool="show_commercial_insights" :color="false"></boolean-icon>
        Umsatz Insights
        <i class="fi-euro"></i>
      </a>

      <a v-if="campaign.reports.length"
         @click="show_cost_metrics = !show_cost_metrics"
         class="media-planning-campaign-reports--button"
         title="Show Cost per mille, Cost per click & Cost per conversion">
        <boolean-icon :bool="show_cost_metrics" :color="false"></boolean-icon>
        <abbr title="Cost per mille">CPM</abbr> /
        <abbr title="Cost per click">CPC</abbr> /
        <abbr title="Cost per conversion">CPCONV</abbr>
        <i class="fi-euro"></i>
      </a>
    </div>

    <template v-if="media_planning.id">
      <div v-if="campaign.reports.length">
        <table>
          <thead class="media-planning--thead">
          <tr>
            <th class="media-planning-campaign-reports--left-aligned">Datum</th>
            <th v-if="!total && !campaign.dummy">Platform</th>
            <th v-if="!campaign.dummy"><abbr title="Impressions">IMPS</abbr></th>
            <th v-if="show_viewable_impressions">
              <abbr title="Viewable impressions">VI</abbr>
            </th>

            <th v-if="campaign_supports_clicks">Clicks</th>
            <th v-if="campaign_supports_clicks"><abbr title="Click through rate">CTR</abbr></th>

            <th v-if="campaign_supports_complete">Completed</th>
            <th v-if="campaign_supports_complete">
              <span v-if="['Video', 'CTvSpot'].includes(campaign.channel_title)">
                <abbr title="Video through rate">VTR</abbr>
              </span>
              <span v-else-if="campaign.channel_title === 'Audio'">
                <abbr title="Listen through rate">LTR</abbr>
              </span>
              <span v-else>
                <abbr title="Complete rate">CR</abbr>
              </span>
            </th>

            <th v-if="campaign_supports_video_views"><abbr title="Video views">VV</abbr></th>
            <th v-if="campaign_supports_video_views">
              <span v-if="['YouTube'].includes(campaign.channel_title)">
                <abbr title="Video view rate">VVR</abbr>
              </span>
            </th>
            <th v-if="campaign_supports_conversions"><abbr title="Conversions">CONVS</abbr></th>
            <th v-if="campaign_supports_conversions"><abbr title="Conversion rate">CVR</abbr></th>
            <th v-if="campaign_supports_conversions"><abbr title="Conversion value">Conv value</abbr></th>
            <th v-if="show_commercial_insights">Umsatz</th>
            <th v-if="show_commercial_insights">Kosten</th>
            <th v-if="show_commercial_insights">Erlös</th>
            <th v-if="show_cost_metrics && campaign_supports_cpm"><abbr title="Cost per mille">CPM</abbr></th>
            <th v-if="show_cost_metrics && campaign_supports_cpc"><abbr title="Cost per click">CPC</abbr></th>
            <th v-if="show_cost_metrics && campaign_supports_conversions">
              <abbr title="Cost per conversion">CPCONV</abbr>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="report in reports">
            <td class="media-planning-campaign-reports--left-aligned">
              {{ format_date_time(report.date) }}
              <i v-if="report.dummy" class="fi-flag media-planning-campaign-reports--report-widget"
                 title="Kalkulatorischer Report"></i>
            </td>
            <td v-if="!total && !campaign.dummy">
              {{ report.platform_title }}
              <small>({{ report.campaign_integration_code }})</small>
            </td>
            <td v-if="!campaign.dummy">
              <template v-if="show_viewable_impressions">
                {{ report.impressions + report.impression_adjustment }}
                <template v-if="!report.use_viewable_impressions">
                  <i v-if="report.impression_adjustment" class="media-planning-campaign-reports--report-widget fi-flag"
                     :title="`Korrigiert um ${report.impression_adjustment}`"></i>
                  <a v-if="can_manage"
                     @click="open_impression_adjustment(report)"
                     class="media-planning-campaign-reports--report-widget"
                     title="Impressions korrigieren">
                    <i class="fi-widget"></i>
                  </a>
                </template>
              </template>
              <template v-else>
                {{ report.get_impressions }}
                <template v-if="!total">
                  <i v-if="report.impression_adjustment"
                     class="media-planning-campaign-reports--report-widget fi-flag"
                     :title="`Korrigiert um ${report.impression_adjustment}`"></i>
                  <a v-if="can_manage"
                     @click="open_impression_adjustment(report)"
                     class="media-planning-campaign-reports--report-widget"
                     title="Impressions korrigieren">
                    <i class="fi-widget"></i>
                  </a>
                </template>
              </template>
            </td>
            <td v-if="show_viewable_impressions">
              <template v-if="report.use_viewable_impressions">
                {{ report.get_impressions }}
                <i v-if="report.impression_adjustment" class="media-planning-campaign-reports--report-widget fi-flag"
                   :title="`Korrigiert um ${report.impression_adjustment}`"></i>
                <a v-if="can_manage"
                   @click="open_impression_adjustment(report)"
                   class="media-planning-campaign-reports--report-widget"
                   title="Impressions korrigieren">
                  <i class="fi-widget"></i>
                </a>
              </template>
              <template v-else>
                0
              </template>
            </td>

            <td v-if="campaign_supports_clicks">
              {{ report.get_clicks }}
              <template v-if="!total">
                <i v-if="report.click_adjustment" class="media-planning-campaign-reports--report-widget fi-flag"
                   :title="`Korrigiert um ${report.click_adjustment}`"></i>
                <a v-if="can_manage"
                   @click="open_click_adjustment(report)"
                   class="media-planning-campaign-reports--report-widget" title="Clicks korrigieren">
                  <i class="fi-widget"></i>
                </a>
              </template>
            </td>
            <td v-if="campaign_supports_clicks">
              <percent-rate :rate="report.click_rate" :min_rate="min_rate(report.channel_title, 'click')">
              </percent-rate>
            </td>

            <td v-if="campaign_supports_complete">
              {{ report.get_complete }}
              <template v-if="!total">
                <i v-if="report.complete_adjustment" class="media-planning-campaign-reports--report-widget fi-flag"
                   :title="`Korrigiert um ${report.complete_adjustment}`"></i>
                <a v-if="can_manage"
                   @click="open_complete_adjustment(report)"
                   class="media-planning-campaign-reports--report-widget" title="Complete korrigieren">
                  <i class="fi-widget"></i>
                </a>
              </template>
            </td>
            <td v-if="campaign_supports_complete">
              <percent-rate :rate="report.complete_rate" :min_rate="min_rate(report.channel_title, 'complete')">
              </percent-rate>
            </td>

            <td v-if="campaign_supports_video_views">
              {{ report.get_video_views }}
              <template v-if="!total">
                <i v-if="report.video_view_adjustment" class="media-planning-campaign-reports--report-widget fi-flag"
                   :title="`Korrigiert um ${report.video_view_adjustment}`"></i>
                <a v-if="can_manage"
                   @click="open_video_views_adjustment(report)"
                   class="media-planning-campaign-reports--report-widget" title="Video views korrigieren">
                  <i class="fi-widget"></i>
                </a>
              </template>
            </td>
            <td v-if="campaign_supports_video_views">
              <percent-rate :rate="report.video_view_rate" :min_rate="min_rate(report.channel_title, 'video_view')">
              </percent-rate>
            </td>

            <td v-if="campaign_supports_conversions">
              <vue-numeric v-if="typeof report.get_conversions === 'number'"
                           :value=report.get_conversions
                           separator="."
                           v-bind:precision=2
                           v-bind:read-only=true>
              </vue-numeric>
              <template v-if="!total">
                <i v-if="report.conversion_adjustment" class="media-planning-campaign-reports--report-widget fi-flag"
                   :title="`Korrigiert um ${report.conversion_adjustment}`"></i>
                <a v-if="can_manage"
                   @click="open_conversions_adjustment(report)"
                   class="media-planning-campaign-reports--report-widget" title="Conversions korrigieren">
                  <i class="fi-widget"></i>
                </a>
              </template>
            </td>
            <td v-if="campaign_supports_conversions">
              <percent-rate :rate="report.conversion_rate" :min_rate="min_rate(report.channel_title, 'conversion')">
              </percent-rate>
            </td>
            <td v-if="campaign_supports_conversions">
              <vue-numeric v-if="typeof report.conversions_value === 'number'"
                           v-model="report.conversions_value"
                           separator="."
                           v-bind:precision=2
                           v-bind:read-only=true
                           currency="€"
                           currency-symbol-position="suffix">
              </vue-numeric>
            </td>

            <td v-if="show_commercial_insights">
              <vue-numeric v-if="report.turnover || report.turnover === 0.0"
                           v-model="report.turnover"
                           separator="."
                           v-bind:precision=2
                           v-bind:read-only=true
                           currency="€"
                           currency-symbol-position="suffix">
              </vue-numeric>
              <boolean-icon v-if="report.turnover === null || report.turnover <= 0.0" :bool="report.turnover > 0.0"
                            :color="true"></boolean-icon>
            </td>
            <td v-if="show_commercial_insights">
              <vue-numeric v-if="report.cost || report.cost === 0.0"
                           v-model="report.cost"
                           separator="."
                           v-bind:precision=2
                           v-bind:read-only=true
                           currency="€"
                           currency-symbol-position="suffix">
              </vue-numeric>
            </td>
            <td v-if="show_commercial_insights">
              <vue-numeric v-if="report.margin() || report.margin() === 0.0"
                           v-model="report.margin()"
                           separator="."
                           v-bind:precision=2
                           v-bind:read-only=true
                           currency="€"
                           currency-symbol-position="suffix">
              </vue-numeric>
              <small v-if="report.margin_percent() || report.margin_percent() === 0.0" class="label secondary">
                <percent-rate :rate="report.margin_percent()" :min_rate="0.3"></percent-rate>
              </small>
              <boolean-icon v-if="report.margin() === null || report.margin() <= 0.0" :bool="report.margin() > 0.0"
                            :color="true"></boolean-icon>
            </td>
            <td v-if="show_cost_metrics && campaign_supports_cpm">
              <vue-numeric v-if="typeof report.cpm() === 'number' && report.cpm() !== Infinity"
                           v-model="report.cpm()"
                           separator="."
                           v-bind:precision=2
                           v-bind:read-only=true
                           currency="€"
                           currency-symbol-position="suffix">
              </vue-numeric>
            </td>
            <td v-if="show_cost_metrics && campaign_supports_cpc">
              <vue-numeric v-if="typeof report.cpc() === 'number' && report.cpc() !== Infinity"
                           v-model="report.cpc()"
                           separator="."
                           v-bind:precision=2
                           v-bind:read-only=true
                           currency="€"
                           currency-symbol-position="suffix">
              </vue-numeric>
            </td>
            <td v-if="show_cost_metrics && campaign_supports_conversions">
              <vue-numeric
                  v-if="typeof report.cost_per_conversion() === 'number' && report.cost_per_conversion() !== Infinity"
                  v-model="report.cost_per_conversion()"
                  separator="."
                  v-bind:precision=2
                  v-bind:read-only=true
                  currency="€"
                  currency-symbol-position="suffix">
              </vue-numeric>
            </td>
          </tr>
          </tbody>

          <tfoot v-if="report_summary" class="media-planning-campaign-reports--tfoot">
          <tr>
            <td class="media-planning-campaign-reports--left-aligned">Gesamt:</td>
            <td v-if="!total && !campaign.dummy"></td>
            <td v-if="!campaign.dummy">{{ report_summary.impressions }}</td>
            <td v-if="show_viewable_impressions">{{ report_summary.viewable_impressions }}</td>

            <td v-if="campaign_supports_clicks">{{ report_summary.clicks }}</td>
            <td v-if="campaign_supports_clicks">
              <percent-rate :rate="report_summary.click_rate"
                            :min_rate="min_rate(report_summary.channel_title, 'click')">
              </percent-rate>
            </td>

            <td v-if="campaign_supports_complete">{{ report_summary.complete }}</td>
            <td v-if="campaign_supports_complete">
              <percent-rate :rate="report_summary.complete_rate"
                            :min_rate="min_rate(report_summary.channel_title, 'complete')">
              </percent-rate>
            </td>

            <td v-if="campaign_supports_video_views">{{ report_summary.video_views }}</td>
            <td v-if="campaign_supports_video_views">
              <percent-rate :rate="report_summary.video_view_rate"
                            :min_rate="min_rate(report_summary.channel_title, 'video_view')">
              </percent-rate>
            </td>

            <td v-if="campaign_supports_conversions">
              <vue-numeric v-if="typeof report_summary.conversions === 'number'"
                           v-model="report_summary.conversions"
                           separator="."
                           v-bind:precision=2
                           v-bind:read-only=true>
              </vue-numeric>
            </td>
            <td v-if="campaign_supports_conversions">
              <percent-rate :rate="report_summary.conversion_rate"
                            :min_rate="min_rate(report_summary.channel_title, 'conversion')"></percent-rate>
            </td>
            <td v-if="campaign_supports_conversions">
              <vue-numeric v-if="report_summary.conversions_value || report_summary.conversions_value === 0.0"
                           v-model="report_summary.conversions_value"
                           separator="."
                           v-bind:precision=2
                           v-bind:read-only=true
                           currency="€"
                           currency-symbol-position="suffix">
              </vue-numeric>
            </td>

            <td v-if="show_commercial_insights">
              <vue-numeric v-if="report_summary.turnover || report_summary.turnover === 0.0"
                           v-model="report_summary.turnover"
                           separator="."
                           v-bind:precision=2
                           v-bind:read-only=true
                           currency="€"
                           currency-symbol-position="suffix">
              </vue-numeric>
            </td>
            <td v-if="show_commercial_insights">
              <vue-numeric v-if="report_summary.cost || report_summary.cost === 0.0"
                           v-model="report_summary.cost"
                           separator="."
                           v-bind:precision=2
                           v-bind:read-only=true
                           currency="€"
                           currency-symbol-position="suffix">
              </vue-numeric>
            </td>
            <td v-if="show_commercial_insights">
              <vue-numeric v-if="report_summary.margin() || report_summary.margin() === 0.0"
                           v-model="report_summary.margin()"
                           separator="."
                           v-bind:precision=2
                           v-bind:read-only=true
                           currency="€"
                           currency-symbol-position="suffix">
              </vue-numeric>
              <small v-if="report_summary.margin_percent() || report_summary.margin_percent() === 0.0"
                     class="label secondary">
                <percent-rate :rate="report_summary.margin_percent()" :min_rate="0.3"></percent-rate>
              </small>
            </td>
            <td v-if="show_cost_metrics && campaign_supports_cpm">
              <vue-numeric
                  v-if="typeof report_summary.cpm() === 'number' && report_summary.cpm() !== Infinity"
                  v-model="report_summary.cpm()"
                  separator="."
                  v-bind:precision=2
                  v-bind:read-only=true
                  currency="€"
                  currency-symbol-position="suffix">
              </vue-numeric>
            </td>

            <td v-if="show_cost_metrics && campaign_supports_cpc">
              <vue-numeric
                  v-if="typeof report_summary.cpc() === 'number' && report_summary.cpc() !== Infinity"
                  v-model="report_summary.cpc()"
                  separator="."
                  v-bind:precision=2
                  v-bind:read-only=true
                  currency="€"
                  currency-symbol-position="suffix">
              </vue-numeric>
            </td>

            <td v-if="show_cost_metrics && campaign_supports_conversions">
              <vue-numeric
                  v-if="(typeof report_summary.cost_per_conversion() === 'number') && report_summary.cost_per_conversion() !== Infinity"
                  v-model="report_summary.cost_per_conversion()"
                  separator="."
                  v-bind:precision=2
                  v-bind:read-only=true
                  currency="€"
                  currency-symbol-position="suffix">
              </vue-numeric>
            </td>
          </tr>
          </tfoot>
        </table>

        <report-impression-adjustment-dialog
            v-if="impression_adjustment_report"
            :media_planning="media_planning"
            :campaign="campaign"
            :report="impression_adjustment_report"
            @closed_requested="impression_adjustment_report = null">
        </report-impression-adjustment-dialog>

        <report-click-adjustment-dialog
            v-if="click_adjustment_report"
            :media_planning="media_planning"
            :campaign="campaign"
            :report="click_adjustment_report"
            @closed_requested="click_adjustment_report = null">
        </report-click-adjustment-dialog>

        <report-complete-adjustment-dialog
            v-if="complete_adjustment_report"
            :media_planning="media_planning"
            :campaign="campaign"
            :report="complete_adjustment_report"
            @closed_requested="complete_adjustment_report = null">
        </report-complete-adjustment-dialog>

        <report-video-views-adjustment-dialog
            v-if="video_views_adjustment_report"
            :media_planning="media_planning"
            :campaign="campaign"
            :report="video_views_adjustment_report"
            @closed_requested="video_views_adjustment_report = null">
        </report-video-views-adjustment-dialog>

        <report-conversions-adjustment-dialog
            v-if="conversions_adjustment_report"
            :media_planning="media_planning"
            :campaign="campaign"
            :report="conversions_adjustment_report"
            @closed_requested="conversions_adjustment_report = null">
        </report-conversions-adjustment-dialog>
      </div>

      <div v-else>Keine Reports vorhanden</div>
    </template>
  </div>
</template>

<script>
import "./media-planning-campaign-reports.scss";
import ReportImpressionAdjustmentDialog
  from "../report-impression-adjustment-dialog/report-impression-adjustment-dialog-app"
import ReportClickAdjustmentDialog from "../report-click-adjustment-dialog/report-click-adjustment-dialog-app"
import ReportCompleteAdjustmentDialog from "../report-complete-adjustment-dialog/report-complete-adjustment-dialog-app"
import ReportVideoViewsAdjustmentDialog
  from "../report-video-views-adjustment-dialog/report-video-views-adjustment-dialog-app"
import ReportConversionsAdjustmentDialog
  from "../report-conversions-adjustment-dialog/report-conversions-adjustment-dialog-app"
import PercentRate from "../percent-rate/percent-rate.vue";
import BooleanIcon from '../boolean-icon/boolean-icon';
import channel_rates from "../../lib/channel-rates";
import Report from "../../models/report"
import moment from "moment";
import VueNumeric from 'vue-numeric';
import axios from "axios"

export default {
  name:       "media-planning-campaign-reports",
  props:      {
    media_planning: {
      required: true,
    },
    campaign:       {
      required: true,
    },
    channels:       {
      required: true,
    },
    can_manage:     {
      required: true,
    }
  },
  components: {
    ReportImpressionAdjustmentDialog,
    ReportClickAdjustmentDialog,
    ReportCompleteAdjustmentDialog,
    ReportVideoViewsAdjustmentDialog,
    ReportConversionsAdjustmentDialog,
    PercentRate,
    BooleanIcon,
    VueNumeric,
  },
  data() {
    return {
      recent:                        true,
      total:                         true,
      active_agent_platform:         true,
      google_ads_platform:           true,
      invidi_platform:               true,
      outbrain_platform:             true,
      spotify_ads_platform:          true,
      hawk_platform:                 true,
      taboola_platform:              true,
      teads_platform:                true,
      verizon_platform:              true,
      xandr_platform:                true,
      show_commercial_insights:      false,
      show_cost_metrics:             false,
      impression_adjustment_report:  null,
      click_adjustment_report:       null,
      complete_adjustment_report:    null,
      video_views_adjustment_report: null,
      conversions_adjustment_report: null,
    }
  },
  methods:  {
    format_date_time(date_time, format = "L") {
      return moment(date_time).format(format)
    },
    min_rate(channel_title, type) {
      return channel_rates(this.channels, channel_title, type)
    },
    open_impression_adjustment(report) {
      this.impression_adjustment_report = report
    },
    open_click_adjustment(report) {
      this.click_adjustment_report = report
    },
    open_complete_adjustment(report) {
      this.complete_adjustment_report = report
    },
    open_video_views_adjustment(report) {
      this.video_views_adjustment_report = report
    },
    open_conversions_adjustment(report) {
      this.conversions_adjustment_report = report
    },
  },
  computed: {
    reports() {
      const eight_days_ago = moment().subtract(8, 'days').startOf('day').format();
      if (this.total) {
        return this.campaign.reports.reduce((accumulator, report) => {
          if (this.recent) {
            if (report.date < eight_days_ago) return accumulator
          }

          const _report = new Report(report)

          const index = accumulator.findIndex(r => {
            return r.date === _report.date
          })

          if (index !== -1) {
            accumulator[index] = accumulator[index].add(_report)
          } else {
            accumulator.push(_report)
          }

          return accumulator
        }, []).sort((r1, r2) => {
          return Date.parse(r1.date) - Date.parse(r2.date)
        })
      } else {
        return this.campaign.reports.reduce((accumulator, report) => {
          if (this.recent) {
            if (report.date < eight_days_ago) return accumulator
          }
          if (!this.active_agent_platform) {
            if (report.platform_title === "ActiveAgent") return accumulator
          }
          if (!this.google_ads_platform) {
            if (report.platform_title === "GoogleAds") return accumulator
          }
          if (!this.invidi_platform) {
            if (report.platform_title === "Invidi") return accumulator
          }
          if (!this.outbrain_platform) {
            if (report.platform_title === "Outbrain") return accumulator
          }
          if (!this.spotify_ads_platform) {
            if (report.platform_title === "SpotifyAds") return accumulator
          }
          if (!this.hawk_platform) {
            if (report.platform_title === "Hawk") return accumulator
          }
          if (!this.taboola_platform) {
            if (report.platform_title === "Taboola") return accumulator
          }
          if (!this.teads_platform) {
            if (report.platform_title === "Teads") return accumulator
          }
          if (!this.verizon_platform) {
            if (report.platform_title === "Verizon") return accumulator
          }
          if (!this.xandr_platform) {
            if (report.platform_title === "Xandr") return accumulator
          }
          accumulator.push(new Report(report))
          return accumulator
        }, []).sort((r1, r2) => {
          return Date.parse(r1.date) - Date.parse(r2.date)
        })
      }
    },
    report_summary() {
      if (!this.campaign.reports.length) return

      return this.campaign.reports.reduce((accumulator, report) => {
        const _report = new Report(report)

        if (!accumulator) return _report

        return accumulator.add(_report)
      }, null)
    },
    campaign_supports_clicks() {
      if (this.campaign.dummy) return
      return this.campaign.channel_supports_clicks
    },
    campaign_supports_complete() {
      if (this.campaign.dummy) return
      return this.campaign.channel_supports_complete
    },
    campaign_supports_video_views() {
      if (this.campaign.dummy) return
      return this.campaign.channel_supports_video_views
    },
    campaign_supports_conversions() {
      if (this.campaign.dummy) return
      return this.campaign.channel_supports_conversions
    },
    campaign_supports_cpm() {
      if (this.campaign.dummy) return
      return this.campaign.channel_supports_cpm
    },
    campaign_supports_cpc() {
      if (this.campaign.dummy) return
      return this.campaign.channel_supports_cpc
    },
    show_viewable_impressions() {
      if (this.campaign.dummy) return
      if (this.campaign.channel_title !== "Native") return
      if (this.total) return
      if (!this.taboola_platform) return

      return this.campaign.reports.some(report => report.use_viewable_impressions)
    },
    available_integrations() {
      return [...new Set(this.campaign.integrations.map(integration => integration.platform_title))]
    },
  },
  mounted() {
    axios({
      method:  'get',
      url:     `/users/current.json`,
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
    })
        .then((response) => {
          this.show_commercial_insights = response.data.show_commercial_insights
          this.show_cost_metrics = response.data.show_cost_metrics
        })
        .catch((error) => {
          console.log("ERROR", error.response.data);
        });
  },
}
</script>
